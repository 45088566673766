import { ElevateApps } from '@amzn/elevate-graphql/types';
import { type QueryClient } from '@tanstack/react-query';
import { type RouteObject } from 'react-router-dom';

import { Actions, DynamicSegments, Models, Paths, Prefixes, StaticSegments } from '@/components/QuestionBank/pathTo';
import { ElevateAppRoutes } from '@/utilities/page-definitions';

export function routes(queryClient: QueryClient) {
  return [
    {
      path: ElevateAppRoutes[ElevateApps.QUESTION_BANK],
      async lazy() {
        const { QuestionBankRouteWrapper } = await import('@/components/QuestionBank/RouteWrapper');
        return {
          element: <QuestionBankRouteWrapper />,
        };
      },
      children: [
        {
          index: true,
          async lazy() {
            const { QuestionBankLandingPage } = await import('@/components/LandingPages/QuestionBankLandingPage');
            return { element: <QuestionBankLandingPage /> };
          },
        },
        {
          path: Prefixes[Models.INTERVIEWS],
          children: [
            {
              index: true,
              async lazy() {
                const { InterviewsList, loader } = await import('@/components/QuestionBank/InterviewsList');
                return { element: <InterviewsList />, loader };
              },
            },
            {
              path: StaticSegments[Actions.CREATE],
              async lazy() {
                const { action } = await import('@/components/QuestionBank/InterviewsCreate');
                return { action };
              },
            },
            {
              path: DynamicSegments[Models.INTERVIEWS],
              async lazy() {
                const { InterviewsDetail, loader } = await import('@/components/QuestionBank/InterviewsDetail');
                return { element: <InterviewsDetail />, loader: loader(queryClient) };
              },
            },
          ],
        },
        {
          path: Prefixes[Models.TEAMS],
          children: [
            {
              index: true,
              async lazy() {
                const { QuestionBankTeamsList } = await import('@/components/QuestionBank/TeamsList');
                return { element: <QuestionBankTeamsList /> };
              },
            },
            {
              path: StaticSegments[Actions.CREATE],
              async lazy() {
                const { QuestionBankTeamsCreate } = await import('@/components/QuestionBank/TeamsCreate');
                return { element: <QuestionBankTeamsCreate /> };
              },
            },
            {
              path: DynamicSegments[Models.TEAMS],
              async lazy() {
                const { loader, QuestionBankTeamsRouteWrapper } = await import(
                  '@/components/QuestionBank/TeamsRouteWrapper'
                );
                const { default: NotFound } = await import('@/components/app/NotFound');

                return {
                  element: <QuestionBankTeamsRouteWrapper />,
                  errorElement: <NotFound />,
                  loader: loader(queryClient),
                };
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    const { QuestionBankTeamsDetail } = await import('@/components/QuestionBank/TeamsDetail');
                    return { element: <QuestionBankTeamsDetail /> };
                  },
                },
                {
                  path: Prefixes[Models.INTERVIEW_TEMPLATES],
                  children: [
                    {
                      path: Paths[Actions.CREATE],
                      async lazy() {
                        const { InterviewTemplatesCreate } = await import(
                          '@/components/QuestionBank/InterviewTemplatesCreate'
                        );
                        return { element: <InterviewTemplatesCreate /> };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: Prefixes[Models.INTERVIEW_TEMPLATES],
          children: [
            {
              index: true,
              async lazy() {
                const { InterviewTemplatesList } = await import('@/components/QuestionBank/InterviewTemplatesList');
                return { element: <InterviewTemplatesList /> };
              },
            },
            {
              path: DynamicSegments[Models.INTERVIEW_TEMPLATES],
              async lazy() {
                const { loader, InterviewTemplatesRouteWrapper } = await import(
                  '@/components/QuestionBank/InterviewTemplatesRouteWrapper'
                );
                const { default: NotFound } = await import('@/components/app/NotFound');

                return {
                  element: <InterviewTemplatesRouteWrapper />,
                  errorElement: <NotFound />,
                  loader: loader(queryClient),
                };
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    const { default: InterviewTemplatesDetail } = await import(
                      '@/components/QuestionBank/InterviewTemplatesDetail'
                    );
                    return { element: <InterviewTemplatesDetail /> };
                  },
                },
                {
                  path: StaticSegments[Actions.UPDATE],
                  async lazy() {
                    const { InterviewTemplatesUpdateRouteWrapper } = await import(
                      '@/components/QuestionBank/InterviewTemplatesUpdate'
                    );
                    return { element: <InterviewTemplatesUpdateRouteWrapper /> };
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        const { default: InterviewTemplatesUpdate } = await import(
                          '@/components/QuestionBank/InterviewTemplatesUpdate'
                        );
                        return { element: <InterviewTemplatesUpdate /> };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ] as RouteObject[];
}
